import React, { FunctionComponent, Fragment } from 'react'

import { AdditionalPanels } from '@components/redesign/AdditionalPanels'
import { JobCard } from '@components/redesign/JobCard'
import { CardList } from '@components/redesign/TwoColumnLayout/CardList'

import { size } from '@utils/helpers'

import { IJobCardListProps } from './JobCardList.types'

import { styles_emptyResultsTitle } from './JobCardList.module.css'

const JobCardList: FunctionComponent<IJobCardListProps> = ({
  jobs = [],
  selectedJob,
  jobsNavigate,
  panels,
  interval = 5,
  paddingBottom
}) => {
  const handleJobNavigate = job => () => {
    jobsNavigate && jobsNavigate(job)
  }

  if (size(jobs) === 0) {
    return <p className={styles_emptyResultsTitle}>No jobs were found.</p>
  }

  return (
    <CardList selectedCard={selectedJob?.id} paddingBottom={paddingBottom}>
      {jobs.map((job, index) => (
        <Fragment key={`job-card-${job.id}`}>
          <AdditionalPanels
            position={index}
            panels={panels}
            count={jobs.length}
            interval={interval}
          />
          <JobCard
            job={job}
            onCardClick={handleJobNavigate(job)}
            isSelected={selectedJob?.id === job.id}
          />
        </Fragment>
      ))}
    </CardList>
  )
}

export default JobCardList
