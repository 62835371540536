import { makeBhApiCall } from '@utils/bullhornHelpers'

import {
  ApplyForJobArgs,
  ApplyForJobBody,
  CreateCandidateBody
} from './jobApplication.types'
import { JobApplicationSubmitErrors } from '@type/jobApplication'

const updateBhCandidateWithResumeData = async (
  candidateId,
  { primarySkills, candidateEducation, candidateWorkHistory }
) => {
  // Create skills to relate to the candidate created
  let skills
  if (primarySkills?.length) {
    skills = await Promise.all(
      primarySkills.map(skill =>
        makeBhApiCall({
          method: 'createSkill',
          body: {
            skill
          }
        })
      )
    )
  }

  const primarySkillIds = skills
    ?.map(skill => skill.data.changedEntityId)
    .join(',')

  const config = {
    method: 'addPrimarySkillsToCandidate',
    body: {
      toManyAssociationEntityIds: primarySkillIds,
      entityId: candidateId
    }
  }
  // Add primary skills associations to a Candidate entity
  await makeBhApiCall(config)

  // Create respective CandidateEducation entries
  if (candidateEducation?.length) {
    await Promise.all(
      candidateEducation.map(education =>
        makeBhApiCall({
          method: 'createCandidateEducation',
          body: {
            candidate: { id: candidateId },
            education
          }
        })
      )
    )
  }

  // Create respective CandidateWorkHistory entries
  if (candidateWorkHistory?.length) {
    await Promise.all(
      candidateWorkHistory.map(workItem =>
        makeBhApiCall({
          method: 'createCandidateWorkHistory',
          body: {
            candidate: { id: candidateId },
            workItem
          }
        })
      )
    )
  }
}

const parseResume = (resume: File) =>
  makeBhApiCall({
    method: 'parseToCandidate',
    body: resume,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

export const fetchBhCandidateByEmail = (email: string) =>
  makeBhApiCall({
    method: 'searchCandidate',
    body: {
      email
    }
  })

export const fetchBhCountries = async () => {
  const config = {
    method: 'searchCountries'
  }

  return await makeBhApiCall(config)
}

export const applyForJob = async ({
  firstName,
  lastName,
  email,
  country,
  hourlyRate,
  resume,
  jobId
}: ApplyForJobArgs) => {
  try {
    const candidate = await fetchBhCandidateByEmail(email)

    const isExistingCandidate = candidate.data.count > 0
    let candidateToUpdate

    if (isExistingCandidate) {
      const existingCandidate = candidate.data.data[0]

      candidateToUpdate = existingCandidate.id
      const hasAppliedForJob = existingCandidate.submissions?.data?.some(
        jobApplication => jobApplication.jobOrder.id === jobId
      )

      if (hasAppliedForJob) {
        return {
          data: null,
          error: JobApplicationSubmitErrors.ALREADY_APPLIED
        }
      }
    }

    const parsedResume = await parseResume(resume[0])

    const { address, companyName, description, mobile, occupation } =
      parsedResume?.data?.data?.candidate ?? {}
    const { candidateEducation, candidateWorkHistory, primarySkills } =
      parsedResume?.data?.data ?? {}

    // Create Candidate
    if (!isExistingCandidate) {
      const newCandidate = await makeBhApiCall<CreateCandidateBody>({
        method: 'createCandidate',
        body: {
          candidateData: {
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
            email,
            address: {
              ...address,
              countryID: country.value,
              countryName: country.label
            },
            hourlyRate: Number.parseInt(hourlyRate),
            companyName,
            description,
            mobile,
            occupation
          }
        }
      })
      candidateToUpdate = newCandidate.data.changedEntityId
    }

    // Update the candidate with related entities
    await updateBhCandidateWithResumeData(candidateToUpdate, {
      candidateEducation,
      candidateWorkHistory,
      primarySkills
    })

    // Apply for a job
    return await makeBhApiCall<ApplyForJobBody>({
      method: 'createJobSubmission',
      body: {
        candidate: { id: candidateToUpdate },
        jobOrder: { id: jobId }
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}
